const SearchInputV2 = ({
    searchTermV2,
    setSearchTermV2,
    onFocusSearchV2
}) => {
    return (
        <div className="col-12">
            <input
                className="search-input"
                value={searchTermV2}
                onChange={(e) => setSearchTermV2(e.target.value)}
                onFocus={() => onFocusSearchV2()}
                placeholder='Search Here....'
            />
        </div>
    )
}

export default SearchInputV2;
