import api from "./createAxios";
import { useEffect, useState } from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
const SearchResultItem = ({ item, lang }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  return (
    <li className="list-group-item">
      <div>
        <div className="col-2">
          {item.product_image && (
            <div
              style={{
                width: "100%",
                height: "100%",
                backgroundImage: `url("${item.product_image}")`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "contain",
              }}
            />
          )}
        </div>
        <div className="col-10">
          <div>
            {lang && lang === "hi" ? item.short_name_hi : item.short_name_en}
          </div>
          {item.sku_id && (
            <small className="text-muted">sku_id: {item.sku_id}</small>
          )}{" "}
          <br />
          {item.product_status && (
            <small className="text-muted">Status: {item.product_status}</small>
          )}{" "}
          - Children -{" "}
          {item.children && item.children.length > 0 ? (
            <button onClick={toggleDrawer}>{item.children.length}</button>
          ) : (
            0
          )}
        </div>
        <Drawer
          open={isOpen}
          onClose={toggleDrawer}
          direction="bottom"
          size={500}
        >
          <div
            style={{
              height: "100%",
              overflow: "auto",
              textAlign: "justify",
              padding: "5px",
            }}
          >
            {item && item.children ? (
              <ul className="list-group">
                {item.children.map((itemChild, idx) => (
                  <SearchResultItemchildren
                    itemChild={itemChild}
                    key={`x-${idx}`}
                    lang={lang}
                  />
                ))}
              </ul>
            ) : (
              <></>
            )}
          </div>
        </Drawer>
      </div>
    </li>
  );
};

const SearchResultItemchildren = ({ itemChild, lang }) => (
  <li className="list-group-item">
    <div>
      <div className="col-2">
        {itemChild.product_image && (
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundImage: `url("${itemChild.product_image}")`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "contain",
            }}
          />
        )}
      </div>
      <div className="col-10">
        <div>
          {lang && lang === "hi"
            ? itemChild.short_name_hi
            : itemChild.short_name_en}
        </div>
        {itemChild.sku_id && (
          <small className="text-muted">sku_id: {itemChild.sku_id}</small>
        )}{" "}
        <br />
        {itemChild.product_status && (
          <small className="text-muted">
            Status: {itemChild.product_status}
          </small>
        )}
      </div>
    </div>
  </li>
);

const SearchResultsV2 = ({ searchParams, token }) => {
  const [response, setResponse] = useState({});
  const [lang, setLang] = useState("en");
  useEffect(() => {
    api
      .get(`/search/api/v2/search/products?searchTerm=${searchParams}`, {
        headers: { Authorization: `${token}` },
      })
      .then((res) => {
        setResponse(res.data);
        setLang(
          res.data && res.data.debug && res.data.debug.params
            ? res.data.debug.params.lang
            : "en"
        );
      })
      .catch((e) => {
        console.error(e);
      });
  }, [searchParams]);
  return (
    <div className="col-12">
      {response &&
      response.searchResults &&
      Array.isArray(response.searchResults) ? (
        <ul className="list-group">
          {response.searchResults.map((item, idx) => (
            <SearchResultItem item={item} key={`x-${idx}`} lang={lang} />
          ))}
        </ul>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SearchResultsV2;
