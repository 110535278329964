import api from "./createAxios";
import React, { useEffect, useState } from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import InfiniteScroll from "react-infinite-scroll-component";
import NoProductsFound from "./components/NoProductsFound";
import toast from "react-hot-toast";

const SearchResultItem = ({ item, lang }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <li
      className="list-group-item"
      style={{
        borderTopWidth: 0,
        borderBottomWidth: 0,
        height: "100%",
      }}
    >
      <div className="row">
        <div className="col-4">
          {item.product_image && (
            <div
              style={{
                width: "100%",
                height: "100%",
                backgroundImage: `url("${item.product_image}")`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "contain",
              }}
            />
          )}
        </div>
        <div className="col-8">
          <div>
            {lang && lang === "hi" ? item.short_name_hi : item.short_name_en}
          </div>
          {item.sku_id && (
            <small className="text-muted">{item.sku_id} / </small>
          )}{" "}
          &#8377;
          {item.price_tl_single_buy && (
            <small className="text-muted">{item.price_tl_single_buy} /</small>
          )}{" "}
          {item.product_status && (
            <small className="text-muted">{item.product_status} </small>
          )}{" "}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "3px",
            }}
          >
            {item.children && item.children.length > 0 ? (
              <button onClick={toggleDrawer}>{item.children.length}</button>
            ) : (
              0
            )}
            <div style={{ display: "flex", flexDirection: "column" }}>
              {item.rank_analytics && (
                <small className="text-muted">RA: {item.rank_analytics} </small>
              )}
              {item.hasOwnProperty("score") && (
                <small className="text-muted">Score: {item.score} </small>
              )}
            </div>
          </div>
        </div>
        <Drawer
          open={isOpen}
          onClose={toggleDrawer}
          direction="bottom"
          size={500}
        >
          <div
            style={{
              height: "100%",
              overflow: "auto",
              textAlign: "justify",
              padding: "5px",
            }}
          >
            {item && item.children ? (
              <ul className="list-group">
                {item.children.map((itemChild, idx) => (
                  <SearchResultItemchildren
                    itemChild={itemChild}
                    key={`x-${idx}`}
                    lang={lang}
                  />
                ))}
              </ul>
            ) : (
              <></>
            )}
          </div>
        </Drawer>
      </div>
    </li>
  );
};

const SearchResultItemchildren = ({ itemChild, lang }) => (
  <li className="list-group-item">
    <div className="row">
      <div className="col-2">
        {itemChild.product_image && (
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundImage: `url("${itemChild.product_image}")`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "contain",
            }}
          />
        )}
      </div>
      <div className="col-10">
        <div>
          {lang && lang === "hi"
            ? itemChild.short_name_hi
            : itemChild.short_name_en}
        </div>
        {itemChild.sku_id && (
          <small className="text-muted">sku_id: {itemChild.sku_id}</small>
        )}{" "}
        <br />
        {itemChild.product_status && (
          <small className="text-muted">
            Status: {itemChild.product_status}
          </small>
        )}{" "}
        {itemChild.price_tl_single_buy && (
          <small className="text-muted">
            - Price: &#8377;{itemChild.price_tl_single_buy}
          </small>
        )}
        <br />
        {itemChild.rank_analytics && (
          <small className="text-muted">
            Rank Analytics: {itemChild.rank_analytics}
          </small>
        )}
        {itemChild.score && (
          <small className="text-muted">Score: {itemChild.score}</small>
        )}
        {itemChild.quantity_sold_bucket && (
          <small className="text-muted">
            QB: {itemChild.quantity_sold_bucket}
          </small>
        )}
        {itemChild.nmv_bucket && (
          <small className="text-muted">NB: {itemChild.nmv_bucket}</small>
        )}
      </div>
    </div>
  </li>
);

const SearchResults = ({ searchParams, token, tab, onSearch }) => {
  const [response, setResponse] = useState({});
  const [responseData, setResponseData] = useState([]);
  const [l1Filters, setL1Filters] = useState([]);
  const [l2Filters, setL2Filters] = useState([]);
  const [selectedL1Filter, setSelectedL1Filter] = useState(null);
  const [selectedL2Filter, setSelectedL2Filter] = useState(null);

  const [totalPage, setTotalPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [lang, setLang] = useState("en");
  const [itemsOnScreen, setItemsOnScreen] = useState(3.5);

  const [bannerItemsWithPosition, setBannerItemsWithPosition] = useState({});

  const uri =
    tab.uriExtra === "/bm25"
      ? `/search/api/v1/admin${tab.uriExtra}/search`
      : `/admin/api/v1${tab.uriExtra}/search`;

  const searchFacets = (searchParams, token, facet_type, facet_value) => {
    api
      .get(
        `${uri}${searchParams}&facet_type=${facet_type}&facet_value=${facet_value}`,
        { headers: { Authorization: `${token}` } }
      )
      .then((res) => {
        setResponse(res.data);
        const activeItems = res.data.results.Active;
        const nonActiveItems = res.data.results.NonActive;
        setLang(
          res.data && res.data.debug && res.data.debug.params
            ? res.data.debug.params.lang
            : "en"
        );
        setResponseData([...responseData, ...activeItems, ...nonActiveItems]);
        // setL1Filters(res.data?.results?.l1_filters || [])
      })
      .catch((e) => {
        console.error(e);
      });
    return;
  };

  const searchPagination = (searchParams, token, pageNumber) => {
    let endpoint = `${uri}${searchParams}&page=${pageNumber}`;

    if (selectedL1Filter)
      Object.entries(selectedL1Filter).forEach(([k, v]) => {
        if (k !== "selected") endpoint += `&${k}=${v}`;
      });
    if (selectedL2Filter)
      Object.entries(selectedL2Filter).forEach(([k, v]) => {
        if (k !== "selected") endpoint += `&${k}=${v}`;
      });
    api
      .get(endpoint, {
        headers: { Authorization: `${token}` },
      })
      .then((res) => {
        setResponse(res.data);
        const activeItems = res.data.results.Active;
        const nonActiveItems = res.data.results.NonActive;
        if (res.data.page.total_pages === pageNumber) {
          setResponseData([...responseData, ...activeItems, ...nonActiveItems]);
        } else {
          setResponseData([...responseData, ...activeItems]);
        }
        // setL1Filters(res.data?.results?.l1_filters || [])
        setCurrentPage(pageNumber);
        setLang(
          res.data && res.data.debug && res.data.debug.params
            ? res.data.debug.params.lang
            : "en"
        );
      })
      .catch((e) => {
        console.error(e);
      });
    return;
  };

  const searchData = (searchParams, fromFilter = false, filterType = null) => {
    setLoading(true);
    let endpoint = `${uri}${searchParams}`;
    if (selectedL1Filter) {
      Object.entries(selectedL1Filter).forEach(([k, v]) => {
        if (k !== "selected") endpoint += `&${k}=${v}`;
      });
    }
    if (selectedL2Filter && filterType === "l2")
      Object.entries(selectedL2Filter).forEach(([k, v]) => {
        if (k !== "selected") endpoint += `&${k}=${v}`;
      });
    api
      .get(endpoint, {
        headers: { Authorization: `${token}` },
      })
      .then((res) => {
        setResponse(res.data);
        const bannersWithPosition =
          res.data.results.banner_horizontal_data.reduce((acc, banner) => {
            const newBanner = {
              ...banner,
              items: banner.items.map((item) => {
                return {
                  ...item,
                  split_rows: banner.split_rows,
                  total:
                    banner.split_rows === true
                      ? Math.ceil(banner.items.length / 2)
                      : banner.items.length,
                  aspectRatio: banner.image_aspect_ratio,
                };
              }),
            };
            acc[newBanner.position] = newBanner;
            return acc;
          }, {});

        setBannerItemsWithPosition(bannersWithPosition);

        const activeItems = res.data.results.Active;
        const nonActiveItems = res.data.results.NonActive;
        if (res.data.page.total_pages === 1) {
          setResponseData([...activeItems, ...nonActiveItems]);
        } else {
          setResponseData([...activeItems]);
        }
        setTotalPage(res.data.page.total_pages);
        if (l1Filters && !l1Filters.length)
          setL1Filters(res.data?.results?.l1_filters || []);
        if ((l2Filters && !l2Filters.length) || filterType !== "l2")
          setL2Filters(res.data?.results?.l2_filters || []);
        setCurrentPage(1);
        setLang(
          res.data && res.data.debug && res.data.debug.params
            ? res.data.debug.params.lang
            : "en"
        );
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        if (e?.response?.status === 500) {
          setResponseData([]);
          toast.error(`Error 500: Internal Server Error`);
        } else if (e?.response?.status === 400) {
          setResponseData([]);
          console.log(e);
          toast.error(`Error ${e.response.status} : ${e.message}`);
        }
      });
  };

  useEffect(() => {
    if (selectedL2Filter) {
      searchData(searchParams, true, "l2");
    } else if (selectedL1Filter) {
      searchData(searchParams, true, "l1");
    } else {
      searchData(searchParams);
    }
  }, [selectedL1Filter, selectedL2Filter, searchParams]);

  const onClickFilter = (filter) => {
    const filters = l1Filters.map((f) => {
      if (f.l1_filter_display_text == filter.l1_filter_display_text) {
        if (filter.selected) {
          setSelectedL1Filter(null);
          setSelectedL2Filter(null);
          return { ...f, selected: false };
        }
        setSelectedL2Filter(null);
        setSelectedL1Filter(filter);
        return { ...f, selected: true };
      } else return { ...f, selected: false };
    });
    setL1Filters(filters);
  };
  const onClickL2Filter = (filter) => {
    const filters = l2Filters.map((f) => {
      if (f.l2_filter_display_text == filter.l2_filter_display_text) {
        if (filter.selected) {
          setSelectedL2Filter(null);
          return { ...f, selected: false };
        }
        setSelectedL2Filter(filter);
        return { ...f, selected: true };
      } else return { ...f, selected: false };
    });
    setL2Filters(filters);
  };
  return (
    <div>
      <div className="col-12">
        {response && response.debug && response.debug.params ? (
          <div>
            <small>{response.debug.params.search_method}</small>
            <br />
            <small>{response.debug.params.product_type}</small>
            <br />
            <small>{response.debug.params.sub_category}</small>
            <br />
            <small>Current Page: {response.page.current} </small> -{" "}
            <small>Size: {response.page.size} </small> -{" "}
            <small>Total Pages: {response.page.total_pages} </small> -{" "}
            <small>Total Results: {response.page.total_results}</small>
          </div>
        ) : (
          <></>
        )}
        <br />
        {l1Filters && (
          <div>
            <small>L1 Filters</small>
            <div className="d-flex" style={{ overflowX: "scroll" }}>
              {l1Filters.map((filter, index) => (
                <button
                  key={index}
                  className="border rounded border-secondary p-0 m-1"
                  style={{
                    backgroundColor: filter.selected ? "lightskyblue" : "",
                    fontSize: "small",
                  }}
                  onClick={(_) => onClickFilter(filter, index)}
                >
                  <small style={{ padding: "0 5px" }}>
                    {filter.l1_filter_display_text}
                  </small>
                </button>
              ))}
            </div>
          </div>
        )}
        {l2Filters && (
          <div>
            <small>L2 Filters</small>
            <div className="d-flex" style={{ overflowX: "scroll" }}>
              {l2Filters.map((filter, index) => (
                <button
                  key={index}
                  className="border rounded border-secondary p-0 m-1"
                  style={{
                    backgroundColor: filter.selected ? "lightskyblue" : "",
                    fontSize: "small",
                  }}
                  onClick={(_) => onClickL2Filter(filter, index)}
                >
                  <small style={{ padding: "0 5px" }}>
                    {filter.l2_filter_display_text}
                  </small>
                </button>
              ))}
            </div>
          </div>
        )}
        {response &&
        response.results &&
        response.results.facets &&
        Array.isArray(response.results.facets.data) ? (
          <div>
            {response.results.facets.data.map((item) => (
              <span
                onClick={() =>
                  searchFacets(
                    searchParams,
                    token,
                    response.results.facets.type,
                    item.value
                  )
                }
                style={{
                  border: "1px solid #C0C0C0",
                  borderRadius: "20px",
                  padding: "3px",
                  margin: "1px",
                }}
              >
                <span
                  style={{
                    color: "#000",
                    fontSize: "12px",
                  }}
                >
                  {item.value}
                </span>{" "}
                <span
                  style={{
                    color: "#808080",
                    fontSize: "10px",
                  }}
                >
                  {item.count}
                </span>
              </span>
            ))}
          </div>
        ) : (
          <></>
        )}
        <br />
        {/* {response &&
        response.results &&
        Array.isArray(response.results.Active) ? (
          <h5>--- Active ---</h5>
        ) : (
          <></>
        )} */}
        {/* {response &&
        response.results &&
        Array.isArray(response.results.Active) ? (
          <ul className="list-group">
            {response.results.Active.map((item, idx) => (
              <SearchResultItem item={item} key={`x-${idx}`} lang={lang} />
            ))}
          </ul>
        ) : (
          <></>
        )} */}
        {/* <br /> */}
        {/* {response &&
        response.results &&
        Array.isArray(response.results.NonActive) ? (
          <h5>--- Non Active ---</h5>
        ) : (
          <></>
        )} */}
        {/* {response &&
        response.results &&
        Array.isArray(response.results.NonActive) ? (
          <ul className="list-group">
            {response.results.NonActive.map((item, idx) => (
              <SearchResultItem item={item} key={`x-${idx}`} lang={lang} />
            ))}
          </ul>
        ) : (
          <></>
        )} */}
        {/* {response && response.length > 0 ? (
          <div>
              {response}
              <InfiniteScroll
                dataLength={10}
                next={() => searchPagination(searchParams, token, response.page.current < response.page.total_pages ? response.page.current + 1 : response.page.total_pages)}
                style={{ display: 'flex', flexDirection: 'column-reverse' }}
                hasMore={true}
                loader={<h4>Loading...</h4>}
                scrollableTarget="scrollableDiv"
              >
                {response &&
                  response.results &&
                  Array.isArray(response.results.Active) ? (
                    <ul className="list-group">
                      {response.results.Active.map((item, idx) => (
                        <SearchResultItem item={item} key={`x-${idx}`} lang={lang} />
                      ))}
                    </ul>
                  ) : (
                    <></>
                  )}
              </InfiniteScroll>
          </div>
        ) : (
          <></>
        )}  */}
        <div>
          <ul className="list-group">
            {!loading && responseData && Array.isArray(responseData) ? (
              responseData.length > 0 ? (
                <InfiniteScroll
                  dataLength={responseData.length}
                  next={() =>
                    searchPagination(
                      searchParams,
                      token,
                      currentPage < totalPage ? currentPage + 1 : totalPage
                    )
                  }
                  hasMore={currentPage === totalPage ? false : true}
                  loader={<h4>Loading...</h4>}
                  scrollableTarget={`scrollable-div-${tab.id}`}
                >
                  {responseData.map((item, idx) => (
                    <React.Fragment key={idx}>
                      {idx % 2 === 0 &&
                        Object.keys(bannerItemsWithPosition).includes(
                          `${idx / 2}`
                        ) &&
                        !bannerItemsWithPosition[`${idx / 2}`].split_rows && (
                          <div
                            style={{
                              background: "#f7f7f7",
                              borderTop: "1px solid #C0C0C0",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "1.5vw",
                                fontWeight: "500",
                                marginLeft: "20px",
                                marginTop: "10px",
                              }}
                            >
                              {bannerItemsWithPosition[`${idx / 2}`].title}
                            </div>
                            <div
                              style={{
                                overflowX: "auto",
                                whiteSpace: "nowrap",
                                padding: "5px 0px",
                              }}
                            >
                              <div
                                style={{
                                  display: "inline-block",

                                  width: `calc(${
                                    (100 *
                                      Math.ceil(
                                        bannerItemsWithPosition[`${idx / 2}`]
                                          .items.length
                                      )) /
                                    bannerItemsWithPosition[idx / 2]
                                      .num_items_on_screen
                                  }%)`,
                                }}
                              >
                                {bannerItemsWithPosition[
                                  `${idx / 2}`
                                ].items.map((item, idx) => (
                                  <img
                                    src={item.image}
                                    alt={item.text}
                                    style={{
                                      cursor: "pointer",
                                      width: `calc(${100 / item.total}%)`,
                                      height: `calc(${
                                        100 / item.total / item.aspectRatio
                                      }% )`,
                                      aspectRatio: `${item.aspectRatio}`,
                                      padding: "12px",
                                      boxSizing: "border-box",
                                      borderRadius: "12%",
                                      background: " #f7f7f7",
                                    }}
                                    onClick={() => onSearch(item.text)}
                                  />
                                ))}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  width: `calc(${
                                    (100 *
                                      bannerItemsWithPosition[`${idx / 2}`]
                                        .items.length) /
                                    bannerItemsWithPosition[idx / 2]
                                      .num_items_on_screen
                                  }%)`,
                                }}
                              >
                                {bannerItemsWithPosition[
                                  `${idx / 2}`
                                ].items.map((item, idx) => (
                                  <p
                                    style={{
                                      width: `calc(${100 / item.total}%)`,
                                      boxSizing: "border-box",
                                      boxSizing: "border-box",
                                      cursor: "pointer",
                                      textAlign: "center",
                                      fontSize: "1.2vw",
                                      marginBottom: "10px",
                                    }}
                                    onClick={() => onSearch(item.text)}
                                  >
                                    {item.text}
                                  </p>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}

                      {idx % 2 === 0 &&
                        Object.keys(bannerItemsWithPosition).includes(
                          `${idx / 2}`
                        ) &&
                        bannerItemsWithPosition[`${idx / 2}`].split_rows && (
                          <div
                            style={{
                              background: "#f7f7f7",
                              borderTop: "1px solid #C0C0C0",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "1.5vw",
                                fontWeight: "500",
                                marginLeft: "20px",
                                marginTop: "10px",
                              }}
                            >
                              {bannerItemsWithPosition[`${idx / 2}`].title}
                            </div>
                            <div
                              style={{
                                overflowX: "auto",
                                whiteSpace: "nowrap",
                                padding: "5px 0px",
                              }}
                            >
                              <div
                                style={{
                                  display: "inline-block",

                                  width: `calc(${
                                    (100 *
                                      Math.ceil(
                                        bannerItemsWithPosition[`${idx / 2}`]
                                          .items.length / 2
                                      )) /
                                    bannerItemsWithPosition[idx / 2]
                                      .num_items_on_screen
                                  }%)`,
                                }}
                              >
                                {bannerItemsWithPosition[`${idx / 2}`].items
                                  .slice(
                                    0,
                                    Math.ceil(
                                      bannerItemsWithPosition[`${idx / 2}`]
                                        .items.length / 2
                                    )
                                  )
                                  .map((item, idx) => (
                                    <img
                                      src={item.image}
                                      alt={item.text}
                                      style={{
                                        cursor: "pointer",
                                        width: `calc(${100 / item.total}%)`,
                                        height: `calc(${
                                          100 / item.total / item.aspectRatio
                                        }% )`,
                                        aspectRatio: `${item.aspectRatio}`,
                                        padding: "12px",
                                        boxSizing: "border-box",
                                        borderRadius: "12%",
                                        background: " #f7f7f7",
                                      }}
                                      onClick={() => onSearch(item.text)}
                                    />
                                  ))}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  width: `calc(${
                                    (100 *
                                      Math.ceil(
                                        bannerItemsWithPosition[`${idx / 2}`]
                                          .items.length / 2
                                      )) /
                                    bannerItemsWithPosition[idx / 2]
                                      .num_items_on_screen
                                  }%)`,
                                }}
                              >
                                {bannerItemsWithPosition[`${idx / 2}`].items
                                  .slice(
                                    0,
                                    Math.ceil(
                                      bannerItemsWithPosition[`${idx / 2}`]
                                        .items.length / 2
                                    )
                                  )
                                  .map((item, idx) => (
                                    <p
                                      style={{
                                        width: `calc(${100 / item.total}%)`,
                                        boxSizing: "border-box",
                                        boxSizing: "border-box",
                                        cursor: "pointer",
                                        textAlign: "center",
                                        fontSize: "1.2vw",
                                        marginBottom: "10px",
                                      }}
                                      onClick={() => onSearch(item.text)}
                                    >
                                      {item.text}
                                    </p>
                                  ))}
                              </div>
                            </div>
                            {/* 2nd row  */}
                            <div
                              style={{
                                overflowX: "auto",
                                whiteSpace: "nowrap",
                                padding: "5px 0px",
                              }}
                            >
                              <div
                                style={{
                                  display: "inline-block",

                                  width: `calc(${
                                    (100 *
                                      Math.ceil(
                                        bannerItemsWithPosition[`${idx / 2}`]
                                          .items.length / 2
                                      )) /
                                    bannerItemsWithPosition[idx / 2]
                                      .num_items_on_screen
                                  }%)`,
                                }}
                              >
                                {bannerItemsWithPosition[`${idx / 2}`].items
                                  .slice(
                                    Math.ceil(
                                      bannerItemsWithPosition[`${idx / 2}`]
                                        .items.length / 2
                                    )
                                  )
                                  .map((item, idx) => (
                                    <img
                                      src={item.image}
                                      alt={item.text}
                                      style={{
                                        cursor: "pointer",
                                        width: `calc(${100 / item.total}%)`,
                                        height: `calc(${
                                          100 / item.total / item.aspectRatio
                                        }% )`,
                                        aspectRatio: `${item.aspectRatio}`,
                                        padding: "12px",
                                        boxSizing: "border-box",
                                        borderRadius: "12%",
                                        background: " #f7f7f7",
                                      }}
                                      onClick={() => onSearch(item.text)}
                                    />
                                  ))}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  width: `calc(${
                                    (100 *
                                      Math.ceil(
                                        bannerItemsWithPosition[`${idx / 2}`]
                                          .items.length / 2
                                      )) /
                                    bannerItemsWithPosition[idx / 2]
                                      .num_items_on_screen
                                  }%)`,
                                }}
                              >
                                {bannerItemsWithPosition[`${idx / 2}`].items
                                  .slice(
                                    Math.ceil(
                                      bannerItemsWithPosition[`${idx / 2}`]
                                        .items.length / 2
                                    )
                                  )
                                  .map((item, idx) => (
                                    <p
                                      style={{
                                        width: `calc(${100 / item.total}%)`,
                                        boxSizing: "border-box",
                                        boxSizing: "border-box",
                                        cursor: "pointer",
                                        textAlign: "center",
                                        fontSize: "1.2vw",
                                        marginBottom: "10px",
                                      }}
                                      onClick={() => onSearch(item.text)}
                                    >
                                      {item.text}
                                    </p>
                                  ))}
                              </div>
                            </div>
                          </div>
                        )}

                      {idx % 2 === 0 && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "stretch",
                            borderTop: "1px solid #C0C0C0",
                          }}
                        >
                          <div
                            className="p-0"
                            style={{
                              flex: 1,
                            }}
                          >
                            <SearchResultItem item={item} lang={lang} />
                          </div>

                          {/* Ensure that the next item exists before rendering */}
                          {responseData[idx + 1] && (
                            <div className="p-0" style={{ flex: 1 }}>
                              <SearchResultItem
                                item={responseData[idx + 1]}
                                lang={lang}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </React.Fragment>
                  ))}
                </InfiniteScroll>
              ) : (
                <NoProductsFound />
              )
            ) : (
              <></>
            )}
          </ul>
          <br />
        </div>
      </div>
    </div>
  );
};

export default SearchResults;
